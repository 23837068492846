<template>
  <vue-draggable-resizable
    :w="300"
    :h="400"
    @dragging="onDrag"
    @resizing="onResize"
    :resizing="true"
    :draggable="true"
    :maximize="true"
    class="indexhigh"
    parent=".im-wraper"
    v-show="dialling || calling || isDialled"
  >
    <!--  -->
    <div class="content">
      <div class="choose" v-show="isDialled">
        <div class="tittle">{{ custom.resource.patientName }}来通话</div>
        <div class="buttons">
          <div class="accept" @click="handleDebounce(accept, 500)">
            <i class="el-icon-phone-outline"></i>
          </div>
          <div class="refuse" @click="handleDebounce(refuse, 500)">
            <i class="el-icon-error"></i>
          </div>
        </div>
      </div>
      <!--  -->
      <div class="call choose" v-show="dialling || calling">
        <!--  -->
        <div
          class="tittle"
          v-if="dialling && currentConversationType === 'C2C'"
        >
          正在呼叫{{ custom.resource.patientName }}...
        </div>
        <div v-show="callType === TRTCCalling.CALL_TYPE.VIDEO_CALL && calling">
          <div class="small-group" id="small-group">
            <div class="video-box" id="local"></div>
            <div
              class="video-box"
              v-for="userId in callingUserList"
              :id="`video-${userId}`"
              :key="`video-${userId}`"
            ></div>
          </div>
        </div>
        <div class="duration" v-show="calling">
          {{ formatDurationStr }}
        </div>
        <div class="buttons">
          <div class="refuse" @click="handleDebounce(leave, 500)">
            <i class="el-icon-error"></i>
          </div>
        </div>
      </div>
    </div>
  </vue-draggable-resizable>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import 'vue-draggable-resizable-gorkys/dist/VueDraggableResizable.css';
import VueDraggableResizable from 'vue-draggable-resizable-gorkys/src/components/vue-draggable-resizable.vue';

import TIM from 'tim-js-sdk';
import { answerConsultation } from '../api/doctor';
//TODO  时间计算有点问题  通话
function formatInt(int) {
  return int < 10 ? `0${int}` : int;
}
function formatDuration(duration) {
  if (duration < 60) {
    return `00:00:${formatInt(duration)}`;
  }
  if (duration < 60 * 60) {
    const min = parseInt(duration / 60);
    const sec = duration - min * 60;
    return `00:${formatInt(min)}:${formatInt(sec)}`;
  }
  const hour = parseInt(duration / (60 * 60));
  const remainder = duration - hour * (60 * 60);
  const min = parseInt(remainder / 60);
  const sec = remainder - min * 60;
  return `${formatInt(hour)}:${formatInt(min)}:${formatInt(sec)}`;
}
export default {
  data() {
    return {
      TIM,
      timeout: null,
      callType: 1, //1:audio，2:video
      Trtc: undefined,
      isCamOn: true,
      isMicOn: true,
      isInvitedMicOn: true,
      maskShow: false,
      isLocalMain: true, // 本地视频是否是主屏幕显示
      start: 0,
      end: 0,
      duration: 0,
      hangUpTimer: 0, // 通话计时id
      ready: false,
      dialling: false, // 是否拨打电话中
      calling: false, // 是否通话中
      isDialled: false, // 是否被呼叫
      inviteID: '',
      inviteData: {},
      sponsor: '', //发起者
      invitedUserID: [], //被邀请者
      invitedNick: '',
      invitedUserInfo: [],
      defaultAvatar:
        'https://imgcache.qq.com/open/qcloud/video/act/webim-avatar/avatar-3.png',
      viewLocalDomID: '',
      callingUserList: [], // 参加通话的人 ,不包括自己
      callingType: 'C2C', //区分多人和C2C通话的UI样式
      isStartLocalView: false, //本地是否开启
      callingTips: {
        callEnd: 1, //通话结束
        callTimeout: 5,
      },
    };
  },

  components: {
    VueDraggableResizable,
  },
  computed: {
    ...mapGetters(['toAccount', 'currentConversationType', 'userID']),
    ...mapState({
      // userID: (state) => state.imInfo.userID,
      // trtcCalling: (state) => state.video.trtcCalling,
      callingInfo: (state) => state.conversation.callingInfo,
      custom: (state) => state.patient.custom,
    }),
    formatDurationStr() {
      return formatDuration(this.duration);
    },
  },
  watch: {
    callingUserList: {
      handler(newValue) {
        if (newValue.length < 2 && this.calling) {
          //单人通话
          this.$nextTick(() => {
            console.log('单人通话', newValue);
            let elementId = `video-${newValue[0]}`;
            let element = window.document.getElementById(elementId);
            let local = window.document.getElementById('local');
            let group_element = window.document.getElementById('small-group');
            // element.addEventListener('click', this.changeMainVideo)
            // local.addEventListener('click', this.changeMainVideo)
            if (!element || !element.classList) {
              return;
            }
            if (!local || !local.classList) {
              return;
            }
            element && element.classList.remove('video-box');
            local && local.classList.remove('video-box');
            group_element && group_element.classList.add('small-group_box');
            this.isLocalMain
              ? element.classList.add('big')
              : element.classList.add('small');
            this.isLocalMain
              ? local.classList.add('small')
              : element.classList.add('big');
          });
          return;
        }
        //多人通话
        // if (newValue.length >= 2 && this.calling) {
        //   let group_element = window.document.getElementById('small-group');
        //   group_element && group_element.classList.remove('small-group_box');
        //   let elements =
        //     window.document.getElementById('small-group').childNodes;
        //   elements.forEach((item, index) => {
        //     if (index === 0) {
        //       item.classList.remove('small');
        //     }
        //     item.classList.remove('big');
        //     item.classList.add('video-box');
        //   });
        // }
      },
      deep: true,
      immediate: true,
    },
    // trtcCalling: {
    //   handler(newValue) {
    //     console.log(newValue, 'newValue');
    //     if (newValue && !newValue._userID) {
    //       this.initListener();
    //     }
    //   },
    //   deep: true,
    // },
  },
  created() {
    this.initListener();
  },

  mounted() {
    this.$bus.$on('video-call', this.videoCalling); // 发起通话
  },
  beforeDestroy() {
    this.$bus.$off('video-call', this.videoCalling);
  },

  destroyed() {
    this.removeListener();
    window.addEventListener('beforeunload', () => {
      this.videoCallLogOut();
    });
    window.addEventListener('leave', () => {
      this.videoCallLogOut();
    });
  },
  methods: {
    initListener() {
      console.log(this.trtcCalling, 'this.trtcCalling');
      // sdk内部发生了错误
      this.trtcCalling.on(this.TRTCCalling.EVENT.ERROR, this.handleError);
      // 被邀请进行通话
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.INVITED,
        this.handleNewInvitationReceived
      );
      // 有用户同意进入通话，那么会收到此回调
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      );
      // 如果有用户同意离开通话，那么会收到此回调
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.USER_LEAVE,
        this.handleUserLeave
      );
      // 用户拒绝通话
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.REJECT,
        this.handleInviteeReject
      );
      //邀请方忙线
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.LINE_BUSY,
        this.handleInviteeLineBusy
      );
      // 作为被邀请方会收到，收到该回调说明本次通话被取消了
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.CALLING_CANCEL,
        this.handleInviterCancel
      );
      // 重复登陆，收到该回调说明被踢出房间
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.KICKED_OUT,
        this.handleKickedOut
      );
      // 作为邀请方会收到，收到该回调说明本次通话超时未应答
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallTimeout
      );
      // 邀请用户无应答
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.NO_RESP,
        this.handleNoResponse
      );
      // 收到该回调说明本次通话结束了
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.CALLING_END,
        this.handleCallEnd
      );
      // 远端用户开启/关闭了摄像头, 会收到该回调
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.USER_VIDEO_AVAILABLE,
        this.handleUserVideoChange
      );
      // 远端用户开启/关闭了麦克风, 会收到该回调
      this.trtcCalling.on(
        this.TRTCCalling.EVENT.USER_AUDIO_AVAILABLE,
        this.handleUserAudioChange
      );
    },
    removeListener() {
      this.trtcCalling.off(this.TRTCCalling.EVENT.ERROR, this.handleError);
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.INVITED,
        this.handleNewInvitationReceived
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.USER_ENTER,
        this.handleUserEnter
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.USER_LEAVE,
        this.handleUserLeave
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.REJECT,
        this.handleInviteeReject
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.LINE_BUSY,
        this.handleInviteeLineBusy
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.CALLING_CANCEL,
        this.handleInviterCancel
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.KICKED_OUT,
        this.handleKickedOut
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.CALLING_TIMEOUT,
        this.handleCallTimeout
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.NO_RESP,
        this.handleNoResponse
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.CALLING_END,
        this.handleCallEnd
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.USER_VIDEO_AVAILABLE,
        this.handleUserVideoChange
      );
      this.trtcCalling.off(
        this.TRTCCalling.EVENT.USER_AUDIO_AVAILABLE,
        this.handleUserAudioChange
      );
    },
    handleError(payload) {
      console.error(payload);
    },
    // 被呼叫  接听方
    async handleNewInvitationReceived(payload) {
      console.log('被呼叫1111', payload);
      const { inviteID, sponsor, inviteData, userIDList, isGroupCall } =
        payload;
      this.inviteID = inviteID;
      this.inviteData = inviteData;
      this.callType = inviteData.callType;
      this.sponsor = sponsor;
      this.invitedUserID = JSON.parse(JSON.stringify(userIDList)); //被邀请者
      this.callingInfo.type = isGroupCall
        ? this.TIM.TYPES.CONV_GROUP
        : this.TIM.TYPES.CONV_C2C;
      this.changeState('isDialled', true);
    },
    accept() {
      this.trtcCalling
        .accept({
          inviteID: this.inviteID,
          roomID: this.inviteData.roomID,
          callType: this.inviteData.callType,
        })
        .then((res) => {
          // this.callType = this.inviteData.callType
          this.changeState('calling', true);
          // this.sendVideoMessage('已接听')
          // res.data.message.nick = '';
          // res.data.message.cloudCustomData = JSON.stringify({
          //   consultationOrderNo: this.custom.consultationOrderNo,
          // });
          // this.$store.commit('pushCurrentMessageList', res.data.message);
        });
    },
    reject() {
      const { callType } = this.inviteData;
      this.trtcCalling
        .reject({
          inviteID: this.inviteID,
          isBusy: false,
          callType,
        })
        .then((res) => {
          res.data.message.nick = '';
          res.data.message.cloudCustomData = JSON.stringify({
            consultationOrderNo: this.custom.consultationOrderNo,
          });
          this.sendVideoMessage('通话已取消');
          // this.$store.commit('pushCurrentMessageList', res.data.message);
        });
    },
    handleDebounce(func, wait) {
      let context = this;
      let args = arguments;
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    },
    // 双方建立连接
    handleUserEnter({ userID }) {
      this.changeState('dialling', true);
      this.isAccept();
      // 判断是否为多人通话
      if (this.callingUserList.length >= 2) {
        this.callingType = this.TIM.TYPES.CONV_GROUP;
      }
      if (this.callingUserList.indexOf(userID) === -1) {
        if (this.callType === this.TRTCCalling.CALL_TYPE.AUDIO_CALL) {
          this.getUserAvatar(userID);
        } else {
          this.callingUserList.push(userID);
        }
      }
      if (this.callType === this.TRTCCalling.CALL_TYPE.VIDEO_CALL) {
        this.$nextTick(() => {
          if (!this.isStartLocalView) {
            this.startLocalView(); //本地只开启一次
          }
          this.startRemoteView(userID); //远端多次拉流
        });
      }
    },
    // 通话已建立
    handleUserLeave({ userID }) {
      if (this.callType === this.TRTCCalling.CALL_TYPE.AUDIO_CALL) {
        // 语音通话
        const _index = this.invitedUserInfo.findIndex(
          (item) => item.userID === userID
        );
        if (_index >= 0) {
          this.invitedUserInfo.splice(_index, 1);
        }
        return;
      }
      const index = this.callingUserList.findIndex((item) => item === userID);
      if (index >= 0) {
        this.callingUserList.splice(index, 1);
      }
    },
    // 通知呼叫方，未接通
    //userID:invitee(被邀请者）
    handleInviteeReject({ userID }) {
      console.log('handleInviteeReject', userID, '拒绝通话');
      if (this.userID === this.sponsor) {
        // 发起者
        this.setCallingstatus(userID);
        // this.sendVideoMessage('对方拒接');
        // this.$store.commit('showMessage', {
        //   message: `${userID}拒绝通话`,
        // });
      }
    },
    setCallingstatus(userID) {
      const _index = this.invitedUserID.indexOf(userID);
      if (_index >= 0) {
        this.invitedUserID.splice(_index, 1);
      }
      if (this.invitedUserID.length === 0) {
        this.changeState('isDialled', false);
        this.changeState('dialling', false);
      }
    },
    // 通知呼叫方，对方在忙碌，未接通
    handleInviteeLineBusy({ sponsor, userID }) {
      // A call B,C call A, A在忙线， 拒绝通话，对于呼叫者C收到通知，XXX在忙线
      if (sponsor === this.userID) {
        this.setCallingstatus(userID);
        this.$store.commit('showMessage', {
          message: `${userID}忙线`,
        });
      }
    },
    // 通知被呼叫方，邀请被取消，未接通
    handleInviterCancel() {
      // 邀请被取消
      this.changeState('isDialled', false);
      this.$store.commit('showMessage', {
        message: '通话已取消',
      });
    },

    handleKickedOut() {
      // //重复登陆，被踢出房间
      // this.trtcCalling.logout();
      // this.$store.commit('userLogoutSuccess');
    },
    // 当自己收到对端超时的信令时，或者当我是被邀请者但自己超时了，通知上层通话超时
    // case: A呼叫B，B在线，B超时未响应，B会触发该事件，A也会触发该事件
    handleCallTimeout({ userIDList }) {
      if (this.calling) {
        return;
      }
      if (this.userID === this.sponsor) {
        // 该用户是邀请者
        userIDList.forEach((userID) => {
          this.setCallingstatus(userID); //超时未接听
        });
        return;
      }
      //用户是被邀请者
      if (userIDList.indexOf(this.userID) > -1) {
        //当超时者是自己时，添加消息
        //会话列表切换后发消息
        this.toAccount &&
          this.sendMessage(this.userID, '', this.callingTips.callTimeout);
      }
      this.changeState('isDialled', false);
    },
    // 双方，通话已建立, 通话结束
    async handleCallEnd({ userID, callEnd }) {
      // 自己挂断的要补充消息  被邀请者都无应答时结束
      // 历史消息中没有通话结束
      this.sendMessage(userID, callEnd, this.callingTips.callEnd);

      if (this.callEnd != 0 && this.custom.answerStatus == 'WAIT') {
        await answerConsultation({
          answerStatus: 'ANSWER',
          orderNo: this.custom.orderNo,
        });
        this.$store.dispatch('updateCustom');
      }

      // if (
      //   (userID === this.userID && this.invitedUserID.length === 0) ||
      //   this.callingUserList === 0
      // ) {
      //   this.sendMessage(userID, callEnd, this.callingTips.callEnd);
      // }
      //   this.changeState('isDialled', false)
      //   this.changeState('calling', false)
      this.changeState('dialling', false);
      this.isMicOn = true;
      this.isCamOn = true;
      this.maskShow = false;
      this.isStartLocalView = false;
      // this.$store.commit('showMessage', {
      //   message: '通话已结束'
      // })
    },
    // 自己超时且是邀请发起者，需主动挂断，并通知上层对端无应答
    handleNoResponse({ sponsor, userIDList }) {
      console.log('handleNoResponse', sponsor, userIDList);
      //邀请者
      if (sponsor === this.userID) {
        userIDList.forEach((userID) => {
          this.setCallingstatus(userID);
        });
        if (userIDList.indexOf(this.userID) === -1) {
          //当超时者是自己时，添加消息
          this.sendMessage(userIDList, '', this.callingTips.callTimeout);
        }
      }
    },
    handleUserVideoChange() {},

    handleUserAudioChange(payload) {
      const _index = this.invitedUserInfo.findIndex(
        (item) => item.userID === payload.userID
      );
      if (_index >= 0) {
        this.invitedUserInfo[_index].isInvitedMicOn = payload.isAudioAvailable;
      }
      // this.isInvitedMicOn = payload.isAudioAvailable
    },

    // 播放本地流
    startLocalView() {
      this.trtcCalling
        .startLocalView({
          userID: this.userID,
          videoViewDomID: 'local',
        })
        .then(() => {
          this.isStartLocalView = true;
        });
    },
    // 播放远端流
    startRemoteView(userID) {
      this.trtcCalling
        .startRemoteView({
          userID: userID,
          videoViewDomID: `video-${userID}`,
        })
        .then(() => {});
    },
    // 没有用到
    stopLocalView() {
      this.trtcCalling.stopLocalView({
        userID: this.userID,
        videoViewDomID: this.viewLocalDomID,
      });
    },

    //没有用到
    stopRemoteView(userID) {
      this.trtcCalling.stopRemoteView({
        userID: this.userID,
        videoViewDomID: `video-${userID}`,
      });
    },
    //获取被呼叫者信息
    getUserAvatar(userID) {
      const _index = this.invitedUserInfo.findIndex(
        (item) => item.userID === userID
      );
      if (_index >= 0) {
        return;
      }
      let _userIDList = [userID];
      let promise = $tim.getUserProfile({
        userIDList: _userIDList, // 请注意：即使只拉取一个用户的资料，也需要用数组类型，例如：userIDList: ['user1']
      });
      promise
        .then((imResponse) => {
          if (imResponse.data[0]) {
            this.invitedUserInfo.push(imResponse.data[0]);
          }
        })
        .catch(() => {});
    },
    leave() {
      // 离开房间，发起方挂断
      this.isMicOn = true;
      this.isCamOn = true;
      this.maskShow = false;
      this.isStartLocalView = false;
      if (!this.calling) {
        // 还没有通话，单方面挂断
        this.trtcCalling.hangup().then((res) => {
          // console.log(res, 99999);
          // res[0].data.message.nick = '';
          // res[0].data.message.cloudCustomData = JSON.stringify({
          //   consultationOrderNo: this.custom.consultationOrderNo,
          // });
          this.sendVideoMessage('拒绝接听');
          // this.$store.commit('pushCurrentMessageList', res[0].data.message);
          this.changeState('dialling', false);
          clearTimeout(this.timer);
        });
        return;
      }
      this.hangUp(); // 通话一段时间之后，某一方面结束通话
    },

    refuse() {
      // 拒绝电话
      this.changeState('isDialled', false);
      this.reject();
    },
    resetDuration(duration) {
      this.duration = duration;
      this.hangUpTimer = setTimeout(() => {
        let now = new Date();
        this.resetDuration(parseInt((now - this.start) / 1000));
      }, 1000);
    },

    isAccept() {
      // 对方接听自己发起的电话
      clearTimeout(this.timer);
      this.changeState('calling', true);
      clearTimeout(this.hangUpTimer);
      this.resetDuration(0);
      this.start = new Date();
    },
    hangUp() {
      // 通话一段时间之后，某一方挂断电话
      this.changeState('calling', false);
      this.trtcCalling.hangup();
      this.$store.commit('showMessage', {
        message: '已挂断',
      });
    },
    videoHandler() {
      // 是否打开摄像头
      if (this.isCamOn) {
        this.isCamOn = false;
        this.maskShow = true;
        this.trtcCalling.closeCamera();
      } else {
        this.isCamOn = true;
        this.maskShow = false;
        this.trtcCalling.openCamera();
      }
    },
    micHandler() {
      // 是否打开麦克风
      if (this.isMicOn) {
        this.trtcCalling.setMicMute(true);
        this.isMicOn = false;
      } else {
        this.trtcCalling.setMicMute(false);
        this.isMicOn = true;
      }
    },
    changeMainVideo() {
      if (!this.calling) {
        return;
      }
      this.isLocalMain = !this.isLocalMain;
    },

    videoCalling() {
      // 发起通话
      // 初始化被邀请者

      this.invitedUserID = JSON.parse(
        JSON.stringify(this.callingInfo.memberList)
      );
      this.sponsor = this.userID;
      if (this.calling) {
        // 避免通话按钮多次快速的点击
        return;
      }
      this.callType = this.TRTCCalling.CALL_TYPE.VIDEO_CALL;
      this.isLocalMain = true;
      // 可设置超时

      this.trtcCalling
        .call({
          userID: this.callingInfo.memberList[0],
          type: this.callType,
          timeout: 30,
        })
        .then((res) => {
          // this.sendVideoMessage('发起视频通话');
          // res.data.message.nick = '';
          // res.data.message.cloudCustomData = JSON.stringify({
          //   consultationOrderNo: this.custom.consultationOrderNo,
          // });

          // console.log(res.data.message, 'res.data.message');
          // this.$store.commit('pushCurrentMessageList', res.data.message);
          this.changeState('dialling', true);
        });
    },
    changeState(state, boolean) {
      let stateList = ['dialling', 'isDialled', 'calling'];
      stateList.forEach((item) => {
        this[item] = item === state ? boolean : false;
      });
      this.$store.commit(
        'UPDATE_ISBUSY',
        stateList.some((item) => this[item])
      ); // 若stateList 中存在 true , isBusy 为 true
    },
    // let gg={
    //   data
    // :
    // "{\"consultationContent\":\"头疼;\",\"consultationImageUrl\":\"\",\"consultationType\":\"1\",\"deptCode\":\"03.02\",\"deptName\":\"消化内科专业\",\"doctorId\":1738079757835673600,\"doctorIm\":\"1738079757835673600\",\"doctorName\":\"郭浩GH\",\"doctorSettlementFee\":\"0\",\"isRevisited\":true,\"openPreConsultationStatus\":true,\"orgCode\":\"42505439731011411A1001\",\"orgName\":\"上海市嘉定区中心医院\",\"patientAge\":40,\"patientIdCard\":\"310110198305022751\",\"patientIm\":\"1635191112000335873\",\"patientMobile\":\"18117130993\",\"patientName\":\"蔡振玮\",\"patientPhoto\":\"https://thirdwx.qlogo.cn/mmopen/vi_32/DYAIOgq83erNxnDzkmaDxcibK7biadhXUYFRiawuO0FymBmRNHK2hEibmejAdBgMJE3fOhCOFTYNicfmHeANEZYbV5A/132\",\"patientSex\":\"1\",\"payFee\":\"0\",\"teamCode\":\"SC_01\",\"teamName\":\"缮瓷医生团队\",\"userId\":1635191112000335873}",
    // description
    // :
    // "",
    // extension
    // :
    // "{\"type\":\"ConsultationSubmitOrder\"}",
    // }
    async sendVideoMessage(messageContent) {
      // const customMessage = $tim.createTextMessage({
      //   to: this.toAccount,
      //   conversationType: this.currentConversationType,
      //   payload: { text: messageContent },
      //   cloudCustomData: JSON.stringify({
      //     consultationOrderNo: this.custom.consultationOrderNo,
      //   }),
      // });
      const customMessage = $tim.createCustomMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          data: JSON.stringify({
            text: messageContent,
          }),
          description: '',
          extension: JSON.stringify({
            type: 'customVideo',
          }),
        },
        cloudCustomData: JSON.stringify({
          consultationOrderNo: this.custom.consultationOrderNo,
        }),
      });

      this.$store.commit('pushCurrentMessageList', customMessage);
      await $tim.sendMessage(customMessage);
    },
    async sendMessage(userId, callEnd, callText) {
      let call_text = '';
      userId = Array.isArray(userId) ? userId.join(',') : userId;

      const message = {};
      switch (callText) {
        case this.callingTips.callEnd:
          call_text =
            callEnd === 0
              ? '取消通话'
              : `通话时长：${formatDuration(callEnd || this.duration)}`;

          if (callEnd != 0 && this.duration >= 5 * 60) {
            await answerConsultation({
              answerStatus: 'ANSWER',
              orderNo: this.custom.orderNo,
            });
          }
          break;

        case this.callingTips.callTimeout:
          call_text = '无应答';
          break;
        default:
          call_text = '';
          break;
      }

      this.sendVideoMessage(call_text);
      // if (this.currentConversationType === this.TIM.TYPES.CONV_GROUP) {
      //   message.groupID = this.toAccount;
      //   let customData = {
      //     operationType: 256,
      //     text: call_text,
      //     userIDList: [],
      //   };
      //   message.payload = customData;
      // }
      // if (this.currentConversationType === this.TIM.TYPES.CONV_C2C) {
      //   let customData = {
      //     text: call_text,
      //   };
      //   message.payload = customData;
      // }
      // if (this.currentConversationType === this.TIM.TYPES.CONV_GROUP) {
      //   message.type = this.TIM.TYPES.MSG_GRP_TIP;
      // }
      // message.callType = 'callingTips';
      // this.$store.commit('pushCurrentMessageList', message);
    },

    onDrag(x, y) {
      // console.log(x, y);
    },
    onResize() {},
  },
};
</script>

<style lang="scss" scoped>
.indexhigh {
  z-index: 999 !important;
}
.content {
  height: 100%;
  width: 100%;
  .video-box {
    width: 100px;
    height: 100px;
  }
  .choose {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    height: 100%;
    width: 100%;
    position: relative;
    .tittle {
      margin: 45% 0 0 0;
      text-align: center;
      width: 100%;
      position: absolute;
      z-index: 10;
      color: #fff;
      font-size: 24px;
      font-weight: 700;
    }
    .buttons {
      cursor: pointer;
      display: flex;
      font-size: 34px;
      position: absolute;
      bottom: 30px;
      justify-content: center;
      width: 100%;
      .accept {
        color: #17b3a3;
        margin-right: 40px;
      }
      .refuse {
        color: red;
      }
    }

    .duration {
      color: #fff;
      position: absolute;
      z-index: 20;
      width: 100%;
      top: 70%;
      display: flex;
      justify-content: center;
    }
  }
}
</style>
